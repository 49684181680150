// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carrinho-tsx": () => import("./../../../src/pages/carrinho.tsx" /* webpackChunkName: "component---src-pages-carrinho-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-finalizar-compra-tsx": () => import("./../../../src/pages/finalizar-compra.tsx" /* webpackChunkName: "component---src-pages-finalizar-compra-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-listas-escolares-tsx": () => import("./../../../src/pages/listas-escolares.tsx" /* webpackChunkName: "component---src-pages-listas-escolares-tsx" */),
  "component---src-pages-livros-tsx": () => import("./../../../src/pages/livros.tsx" /* webpackChunkName: "component---src-pages-livros-tsx" */),
  "component---src-pages-minha-conta-tsx": () => import("./../../../src/pages/minha-conta.tsx" /* webpackChunkName: "component---src-pages-minha-conta-tsx" */),
  "component---src-pages-nova-conta-tsx": () => import("./../../../src/pages/nova-conta.tsx" /* webpackChunkName: "component---src-pages-nova-conta-tsx" */),
  "component---src-pages-ordem-enviada-tsx": () => import("./../../../src/pages/ordem-enviada.tsx" /* webpackChunkName: "component---src-pages-ordem-enviada-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */)
}

